@charset "UTF-8";
@font-face {
  font-family: Inter;
  font-weight: 100 900;
  font-style: oblique 0deg -10deg;
  src: url(../assets/fonts/Inter.var.woff2); }

@font-face {
  font-family: 'sdfa';
  src: url(../assets/fonts/); }

html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0; }

body {
  background-color: white;
  display: grid;
  grid-template-areas: ". . ." "nav content .";
  grid-template-columns: 0.5fr 1fr 0.5fr;
  grid-auto-rows: 10% auto; }

.deemphasize {
  color: lightgray; }

h1, h2, h3, h4, h5, h6 {
  font-family: Inter, Helvetica, sans-serif;
  font-weight: 900;
  display: block;
  margin: 0 auto; }

h1 {
  font-size: 6rem; }

h2 {
  font-size: 4rem; }

h3 {
  font-size: 3rem; }

h4 {
  font-size: 2.5rem; }

h5 {
  font-size: 2.25rem; }

h6 {
  font-size: 2rem; }

p {
  font-family: Copse, Georgia, serif;
  line-height: 2.5rem; }

p a {
  text-decoration: underline;
  text-decoration-color: #99ddff;
  text-decoration-thickness: 3px;
  color: black; }
  p a:hover {
    color: #0055aa; }

body.homepageLayout {
  grid-template-areas: ". . ." "nav titles ." "nav description ." ". . .";
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr auto auto 1fr; }
  body.homepageLayout .mainTitles {
    grid-area: titles; }
    body.homepageLayout .mainTitles h1 {
      transition: color 200ms;
      -webkit-text-stroke: 2px;
      -webkit-text-stroke-color: black;
      font-size: 10rem;
      color: transparent; }
      body.homepageLayout .mainTitles h1 a {
        color: inherit;
        text-decoration: none; }
      body.homepageLayout .mainTitles h1 .deemphasize {
        -webkit-text-stroke-color: lightgray;
        color: transparent; }
    body.homepageLayout .mainTitles h2 {
      transition: color 200ms;
      transition: font-weight 200ms;
      font-style: oblique -10deg;
      font-weight: 150;
      font-size: 4rem; }
    body.homepageLayout .mainTitles:hover h1 {
      color: #0055aa;
      -webkit-text-stroke-color: #0055aa; }
    body.homepageLayout .mainTitles:hover h2 {
      color: #99ddff;
      font-weight: 850; }
  body.homepageLayout p {
    grid-area: description; }

nav {
  grid-area: nav;
  text-align: right;
  margin-right: 15%; }
  nav ul {
    list-style: none;
    border-right: 3px solid #0088cc;
    margin-right: 10px;
    padding-right: 10px; }
    nav ul:has(> .active) {
      border-right: 3px solid #002266; }
    nav ul li {
      margin: 1rem auto; }
  nav #navIcon img {
    margin-top: 1.2rem;
    height: 9rem;
    right: 0; }
  nav li {
    font-family: Inter, Helvetica, sans-serif;
    color: black;
    font-weight: 400; }
  nav a {
    transition: color 100ms;
    transition: font-weight 100ms;
    font-weight: 700;
    color: inherit;
    text-decoration: none; }
    nav a:hover {
      color: #0055aa;
      font-weight: 300; }
  nav .external a:before {
    content: '↖'; }

#content {
  grid-area: content;
  max-width: 70%; }

aside {
  float: left;
  margin: 1rem; }

.circular {
  shape-outside: circle(50%);
  border-radius: 50%;
  width: 10rem;
  height: 10rem; }

.fullwidth {
  width: 100%; }

.thirds {
  width: 31%;
  margin: 1%; }

.black-bg {
  background-color: black; }
